import React, { useState } from 'react';

export const WelcomeModalContext = React.createContext();

const Provider = props => {
  const [showWelcomeModal, setShowWelcomeModal] = useState(typeof window !== 'undefined' ? window.location.search === '' : false);

  return (
    <WelcomeModalContext.Provider value={{
      showWelcomeModal,
      closeWelcomeModal: () => setShowWelcomeModal(false)
    }}>
      {props.children}
    </WelcomeModalContext.Provider>
  )
};

export default ({ element }) => (
  <Provider>
    {element}
  </Provider>
);
