exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-attribution-scholarship-js": () => import("./../../../src/pages/attribution-scholarship.js" /* webpackChunkName: "component---src-pages-attribution-scholarship-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contributors-js": () => import("./../../../src/pages/contributors.js" /* webpackChunkName: "component---src-pages-contributors-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-[id]-js": () => import("./../../../src/pages/news/[id].js" /* webpackChunkName: "component---src-pages-news-[id]-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-periodicals-js": () => import("./../../../src/pages/periodicals.js" /* webpackChunkName: "component---src-pages-periodicals-js" */),
  "component---src-pages-related-sites-js": () => import("./../../../src/pages/related-sites.js" /* webpackChunkName: "component---src-pages-related-sites-js" */),
  "component---src-pages-submissions-js": () => import("./../../../src/pages/submissions.js" /* webpackChunkName: "component---src-pages-submissions-js" */),
  "component---src-pages-user-guide-js": () => import("./../../../src/pages/user-guide.js" /* webpackChunkName: "component---src-pages-user-guide-js" */)
}

